import React from "react";
import Layout from "@layout";
import SEO from "@components/seo";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils/functions";
import Footer from "../layouts/footer/layout-01";
import Header from "../layouts/header/layout-dark";
import BlogRightSidebarArea from "../container/blog-page/layout-02";

const BlogPage = ({ data }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    const content = normalizedData(data?.page?.content || []);
    return (
        <Layout>
            <SEO title="Home page" pathname="/" />
            <Header data={{ ...globalContent?.["menu"] }} />
            <BlogRightSidebarArea
                data={{
                    items: data?.latestPosts?.nodes,
                    allPosts: data?.allPosts?.nodes,
                }}
            />
            <Footer data={{ ...globalContent?.["footer"] }} />
        </Layout>
    );
};

BlogPage.propTypes = {
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        latestPosts: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allPosts: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};
export const query = graphql`
    query BlogPageQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        page(title: { eq: "home" }, pageType: { eq: homepage }) {
            content {
                ...PageContentAll
            }
        }
        allPosts: allArticle(sort: { fields: postedAt___date, order: DESC }) {
            nodes {
                ...Articles
                image {
                    alt
                    src {
                        childImageSharp {
                            gatsbyImageData(height: 436, quality: 100)
                        }
                    }
                }
            }
        }
        latestPosts: allArticle(
            limit: 3
            sort: { fields: postedAt___date, order: DESC }
        ) {
            nodes {
                ...Articles
                image {
                    alt
                    src {
                        childImageSharp {
                            gatsbyImageData(height: 84, quality: 100, width: 80)
                        }
                    }
                }
            }
        }
    }
`;
export default BlogPage;
